@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark-mixin";

.ag-theme-custom {
  @include ag-theme-balham-dark((
    grid-size: 4.5px,
    borders: false,
    borders-critical: true,
    background-color: #373737,
    header-background-color: #373737,
    odd-row-background-color: #373737,
    input-focus-box-shadow: false,
    input-border-color: rgba(245, 245, 245, 0.3),
    subheader-background-color: #373737,
    subheader-toolbar-background-color: #373737,
    control-panel-background-color: #373737,
    checkbox-checked-color: #20c2db,
    checkbox-unchecked-color: #A9A9A9,
    checkbox-indeterminate-color: #20c2db
  ));

  .ag-menu {
    border: 1px solid #5c5c5c;
    border-radius: 4px;
  }

  .ag-tool-panel-wrapper {
    margin-right: 5px;
  }

  .ag-filter-toolpanel-group-title {
    color: #FDBA31;
    font-size: 13px;
  }
}
